/*=========================================================================================
  File Name: actions.js
  Description:
  ----------------------------------------------------------------------------------------
  Item Name:

==========================================================================================*/
import {checkLoginStatus, loginWithEmailPassword, logoutFirebase} from "@/services/auth/auth";
import {getUserByEmail} from "@/services/user/userService";
import store from "@/store";

const actions = {
    update_info_user({commit}, value) {
        commit('UPDATE_INFO_USER', value);
    },
    set_properties({commit}, properties) {
        commit('SET_INFO_ARRAY_PROJECTS', properties)
    },
    get_selected_language({commit}, value) {
        commit('GET_SELECTED_LANGUAGE', value)
    },
    // ============= Wallet Actions ===================
    set_wallet_info({commit}, value) {
        commit('SET_WALLET_INFO', value);
    },
    disconnectWallet({commit}) {
        commit('DISCONNECT_WALLET');
        sessionStorage.removeItem("token");
    },

    set_my_properties({commit}, value) {
        commit('SET_MY_PROPERTIES', value)
    },
    set_ui({commit}, value) {
        commit('SET_UI', value)
    },
    async signInUser({commit}, user) {
        const {email, password} = user
        try {
            // Llama al método de inicio de sesión de Firebase
            const responseFirebase = await loginWithEmailPassword(email, password);
            if (!responseFirebase.ok) return {ok: false};
            const auth = {
                idToken: responseFirebase.user.idToken,
                refreshToken: responseFirebase.user.refreshToken,
                isAuthenticated: true
            }
            commit("SET_AUTH", auth);
            // Obtenermos los datos del usario de la DB
            const responseGetUser = await getUserByEmail(email);
            if (!responseGetUser.ok) return {ok: false};
            let newUser = Object.assign({}, store.state.user, responseGetUser.user);
            commit("SET_USER", newUser);

            return {ok: true}

        } catch (error) {
            return {ok: false, message: error.response.data.error.message}
        }
    },

    async checkAuthentication({commit}) {
        try {
            // Llama al método de inicio de sesión de Firebase
            const responseFirebase = await checkLoginStatus();
            if (!responseFirebase) {
                commit('SET_LOGOUT');
                return {ok: false, message: 'There is not user'}
            }
            const auth = {
                idToken: responseFirebase.stsTokenManager.accessToken,
                refreshToken: responseFirebase.stsTokenManager.refreshToken,
                isAuthenticated: true
            }
            commit("SET_AUTH", auth);
            // Obtenermos los datos del usario de la DB
            const responseGetUser = await getUserByEmail(responseFirebase.email);
            if (!responseGetUser.ok) {
                commit('SET_LOGOUT');
                return {ok: false, message: 'There is not user'}
            }

            let newUser = Object.assign({}, store.state.user, responseGetUser.user);

            commit("SET_USER", newUser);

            return {ok: true}

        } catch (error) {
            return {ok: false, message: error}
        }
    },

    async logout({commit}) {
        await logoutFirebase();
        commit('SET_LOGOUT');
    },

    set_project_token_address({commit}, value) {
        commit('SET_PROJECT_TOKEN_ADDRESS', value)
    },

}

export default actions
