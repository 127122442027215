import axios from 'axios';

const keyConnectApi = axios.create({
    headers: {
        'content-type': 'application/json',
        'apiKey': process.env.VUE_APP_API_KEY,
    }
});

export default keyConnectApi;
