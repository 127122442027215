
export default {
    component: () => import(/* webpackChunkName: "Auth Layout" */ '@/modules/auth/layouts/AuthLayout.vue'),
    children: [
       {
           path: '',
           name: 'login',
           component: () => import(/* webpackChunkName: "Login" */ '@/modules/auth/views/Login.vue'),
       },
        {
            path: 'login',
            component: () => import(/* webpackChunkName: "Login" */ '@/modules/auth/views/Login.vue'),
        },
       {
           path: 'register',
           name: 'register',
           component: () => import(/* webpackChunkName: "Register" */ '@/modules/auth/views/Register.vue'),
       },
        {
            path: 'verify-mail',
            name: 'verify-mail',
            component: () => import(/* webpackChunkName: "VerifyMail" */ '@/modules/auth/views/VerificationEmailView.vue'),
        },
        {
            path: 'verify-number',
            name: 'verify-number',
            component: () => import(/* webpackChunkName: "VerifyNumber" */ '@/modules/auth/views/VerificationNumberView.vue'),
        },
        {
            path: 'reset-password',
            name: 'reset-password',
            component: () => import(/* webpackChunkName: "ResetPassword" */ '@/modules/auth/views/ResetPasswordView.vue'),
        },
    ]

}
