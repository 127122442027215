import axios from 'axios';
import {checkIdToken} from "@/services/auth/auth";

const userApi = axios.create({
    headers: {
        'content-type': 'application/json',
        'apiKey': process.env.VUE_APP_API_KEY,
    }
});

userApi.interceptors.request.use(async function (config) {
    let token = await checkIdToken();
    token = token.idToken;
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});


export default userApi;
