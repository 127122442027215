/*=========================================================================================
  File Name: mutations.js
  Description:
  ----------------------------------------------

==========================================================================================*/


const mutations = {

    // ////////////////////////////////////////////
    // SIDEBAR & UI UX
    // ////////////////////////////////////////////

    SET_INFO_ARRAY_PROJECTS(state, properties) {
        state.properties = properties;
    },
    GET_SELECTED_LANGUAGE(state, value) {
        state.languageSelected = value;
    },
// =================== Wallet Mutations ================================
    SET_WALLET_INFO(state, value) {
        state.wallet = value;
    },
    DISCONNECT_WALLET(state) {
        state.wallet = {
            chain: process.env.VUE_APP_MAIN_CHAIN,
            isMetamaskInstalled: false,
            address: '',
            BNBBalance: 0,
            BNBUsdBalance: 0,
            BUSDBalance: 0,
            BUSDUsdBalance: 0,
            isConnected: false,
            isLoading: false,
            isMetamask: null,
            prices: {
                "USD": 0,
                "BNB": 0,
                "USDT": 0,
                "BSC-USD": 0
            }
        }
    },
    SET_MY_PROPERTIES(state, value){
        state.myProperties = value
    },

    // AUTH
    SET_AUTH(state, value){
        state.auth = value
    },
    SET_LOGOUT(state){
        state.user = null;
        state.auth = {
            idToken: null,
            refreshToken: null,
            isAuthenticated: false
        }
    },

    // USER
    SET_USER(state, value){
        state.user = value
    },
    UPDATE_INFO_USER(state, value) {
        state.user = value;
    },
    SET_UI(state, value) {
        state.ui = value;
    },
    SET_PROJECT_TOKEN_ADDRESS(state, value) {
        state.projectTokenAddresses = value;
    },
}

export default mutations
