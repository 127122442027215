import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import VueGtag from 'vue-gtag'
import vuetify from './plugins/vuetify';
import Meta from 'vue-meta'; //SEO

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: 'G-5GKM1510GP' }
})
// Configuración libreria para SEO
Vue.use(Meta)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
