import store from "@/store";


export const isAuthenticatedGuard = async (to, from, next) => {
    const {ok} = await store.dispatch('checkAuthentication');

    if (!ok) {
        // Redirecciona a la página de inicio de sesión si el usuario no está autenticado
        next({name: 'landing'});
    } else {
        // Permite el acceso a la ruta protegida si el usuario está autenticado
        next();
    }
}

export const userGuard = async (to, from, next) => {
    const {ok} = await store.dispatch('checkAuthentication');

    if (ok) {
        // Redirecciona a la página de inicio de sesión si el usuario no está autenticado
        next({name: 'landing'});
    } else {
        // Permite el acceso a la ruta protegida si el usuario está autenticado
        next();
    }
}
