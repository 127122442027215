import {
    getAuth,
    GoogleAuthProvider,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup
} from 'firebase/auth';
import {FirebaseAuth} from "@/config/firebaseConfig";


const googleProvider = new GoogleAuthProvider();

const auth = getAuth();

const singInWithGoogle = async () => {
    try {
        const result = await signInWithPopup(auth, googleProvider);
        // const credentials = GoogleAuthProvider.credentialFromResult( result );
        const {displayName, email, photoURL, uid} = result.user;

        return {
            ok: true,
            // User info
            displayName, email, photoURL, uid
        }
    } catch (error) {
        // const errorCode = error.code;
        const errorMessage = error.message;

        return {
            ok: false,
            errorMessage,
        }
    }

}


const loginWithEmailPassword = async (email, password) => {
    try {
        const resp = await signInWithEmailAndPassword(FirebaseAuth, email, password);
        const userEmail = resp.user.email;
        const {idToken, refreshToken} = resp._tokenResponse;

        return {
            ok: true,
            user: {email: userEmail, idToken, refreshToken}
        }
    } catch (error) {
        return {ok: false, errorMessage: error.message}
    }
}

const checkIdToken = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    try {
        const idToken = await user.getIdToken();
        return {
            ok: true,
            idToken
        }
    } catch (error) {
        return {ok: false, errorMessage: error.message}
    }
}

const checkLoginStatus = async () => {
    return new Promise(function (resolve, reject) {
        onAuthStateChanged(auth, function (user) {
            if (user) {
                resolve(user);
            } else {
                reject(null);
            }
        });
    });
}

const logoutFirebase = async () => {
    return await auth.signOut();
}

const resetPasswordWithEmail = async (email) => {
    try {
        await sendPasswordResetEmail(FirebaseAuth, email)
        return {ok: true}
    } catch (error) {
        return {ok: false, errorMessage: error.message}
    }
}




export {
    singInWithGoogle,
    loginWithEmailPassword,
    logoutFirebase,
    checkLoginStatus,
    checkIdToken,
    resetPasswordWithEmail,
}
