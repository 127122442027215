
export default {
    component: () => import(/* webpackChunkName: "homeLayoutLegal" */ '@/layouts/HomeLayout'),
    children: [
       {
           path: 'terms',
           name: 'terms',
           component: () => import(/* webpackChunkName: "TermsServiceView" */ '@/modules/legal/views/TermsServiceView'),
       },
    ]

}
