
export default {
    component: () => import(/* webpackChunkName: "homeLayoutAboutUs" */ '@/layouts/HomeLayout'),
    children: [
       {
           path: '',
           name: 'about-us',
           component: () => import(/* webpackChunkName: "aboutUsView" */ '@/modules/about-us/views/AboutUsView'),
       },
        {
            path: 'contact',
            name: 'contact',
            component: () => import(/* webpackChunkName: "contactUsView" */ '@/modules/about-us/views/ContactUsView'),
        },
    ]

}
