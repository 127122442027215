
import keyConnectApi from "@/api/keyConnectApi";

// Obtener todas las propiedades
const getProperties = async () => {
    try {
        const url = process.env.VUE_APP_PROPS;
        const responseGetProperties = await keyConnectApi.get(url);
        return {
            ok: true,
            properties: responseGetProperties.data.data
        };
    } catch (error) {
        console.log(error);
        return {
            ok: false,
            errorMessage: error
        };
    }
}


// Obtener el detalle de una propiedad
const getPropertyById = async (id) => {
    try {
        const url = `${process.env.VUE_APP_PROPS_BY_ID}/${id}`;
        const responseGetProperty = await keyConnectApi.get(url);
        return {
            ok: true,
            property: responseGetProperty.data.data
        };
    } catch (error) {
        console.log(error);
        return {
            ok: false,
            errorMessage: error
        };
    }
}

// Obtener la lista de tokens STO de las propiedades
const getPropertyTokens = async () => {
    try {
        const url = `${process.env.VUE_APP_PROPS_TOKENS}`;
        const responseGetPropertyTokens = await keyConnectApi.get(url);
        return {
            ok: true,
            propertyTokens: responseGetPropertyTokens.data.data
        };
    } catch (error) {
        console.log(error);
        return {
            ok: false,
            errorMessage: error
        };
    }
}
export {
    getProperties,
    getPropertyById,
    getPropertyTokens
}
