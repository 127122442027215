<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import {getProperties} from "@/services/properties/propertyService";

export default {
  name: 'App',

  data: () => ({
    //
  }),
  beforeMount() {
    // cuando se crea el javascript
    getProperties().then((data) => {
      if (data.ok) this.$store.dispatch('set_properties', data.properties)
    });
    this.$store.dispatch("checkAuthentication");
  },

};
</script>

<style>
/*@font-face {*/
/*  font-family: "La Nord";*/
/*  src: local("La Nord"),*/
/*  url(assets/fonts/nord/Nord-Regular.ttf);*/
/*}*/
</style>
