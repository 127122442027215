/*=========================================================================================
  File Name: state.js
  Description:
  ----------------------------------------------------------------------------------------
  Variables globales
==========================================================================================*/


const state = {
    auth: {
        idToken: null,
        refreshToken: null,
        isAuthenticated: false
    },

    // objeto del usuario
    user: {
        addressOfResidence: '',
        cityOfResidence: '',
        countryOfResidence: '',
        documentId: '',
        email: '',
        emailVerified: false,
        lastName: '',
        name: '',
        birthdate: '',
        newUser: true,
        phone: '',
        phoneWithoutCode: '',
        phoneVerified: false,
        status: '',
        termsOfService: false,
        password: '',
        assets: []
    },
    // Propiedades del Marketplace
    properties: [],

    // State of Wallet
    wallet: {
        chain: process.env.VUE_APP_MAIN_CHAIN,
        isMetamaskInstalled: false,
        address: '',
        BNBBalance: 0,
        BNBUsdBalance: 0,
        BUSDBalance: 0,
        BUSDUsdBalance: 0,
        totalBalance: 0,
        investedBalance: 0,
        isConnected: false,
        isLoading: false,
        isMetamask: null,
        isMoralisStarted: false,
        //networkMessage: "",
        prices: {
            "USD": 0,
            "BNB": 0,
            "USDT": 0,
            "BSC-USD": 0
        }
    },

    // Mis propiedades compradas
    myProperties: {
        isLoading: false,
        isUpdated: false,
        tokens: []
    },

    // Estado para eventos del UI
    ui: {
        alertDialog: false,
        transactionSuccessfullyDialog: false,
        investConfirmationDialog: false,
        isLoadingInvestment: false,
        messageAlert: '',
        videoPlayer: ''
    },

    KYCCompleted: false,

    // Tokens STO de los proyectos disponibles
    projectTokenAddresses: []
}

export default state
