import userApi from "@/api/userApi";
import keyConnectApi from "@/api/keyConnectApi";
import store from "@/store";

// Obtener Usuario
const getUserByEmail = async (email) => {
    try {
        const url = process.env.VUE_APP_USER_BY_EMAIL;
        const responseGetUsers = await userApi.get(`${url}/${email}`);
        return {
            ok: true,
            user: responseGetUsers.data.data
        };
    } catch (error) {
        console.log(error);
        return {
            ok: false,
            errorMessage: error
        };
    }
}

const checkUserExistsByEmail = async (email) => {
    try {
        const url = process.env.VUE_APP_CHECK_USER_BY_EMAIL;
        const responseGetUsers = await keyConnectApi.get(`${url}/${email}`);
        return {
            ok: true,
            userExist: responseGetUsers.data.data
        };
    } catch (error) {
        console.log(error);
        return {
            ok: false,
            errorMessage: error
        };
    }
}

const createUser = async (user) => {
    try {
        const url = process.env.VUE_APP_USER_CREATE;
        const responseCreateUser = await keyConnectApi.post(url, user);
        return {
            ok: true,
            userCreated: responseCreateUser.data.data
        };
    } catch (error) {
        console.log(error);
        return {
            ok: false,
            errorMessage: error
        };
    }
}

const updateUser = async (userId , user) => {
    try {
        console.log('userId', userId)
        console.log('user', user)
        const url = process.env.VUE_APP_USER_UPDATE;
        const responseGetUsers = await userApi.put(`${url}/${userId}`, user);
        return {
            ok: true,
            user: responseGetUsers.data.data
        };
    } catch (error) {
        console.log(error);
        return {
            ok: false,
            errorMessage: error
        };
    }
}
// Verificar si el usuario esta loggeado o tiene wallet conectada o esta verificado
const checkUserAndWallet = async () => {
    const {ok} = await store.dispatch('checkAuthentication');
    const isConnected = store.state.wallet.isConnected;
    const verificationStatus = store.state.user.status;

    return (!ok || !isConnected || verificationStatus === 'verified');
}


export {
    getUserByEmail,
    checkUserExistsByEmail,
    createUser,
    updateUser,
    checkUserAndWallet
}
