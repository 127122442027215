import store from '@/store'

export default {
    component: () => import(/* webpackChunkName: "homeLayoutMarketplace" */ '@/layouts/HomeLayout'),
    children: [
        {
            path: '',
            name: 'marketplace',
            component: () => import(/* webpackChunkName: "marketplace" */ '@/modules/marketplace/views/Marketplace'),
        },
        {
            path: ':id',
            // path: 'marketplace/:id',
            name: 'property-details-view',
            component: () => import(/* webpackChunkName: "propertyDetailsView" */ '@/modules/marketplace/views/PropertyDetails.vue'),
        },
        {
            path: '/checkout/:id',
            name: 'checkout',
            component: () => import(/* webpackChunkName: "propertyDetailsView" */ '@/modules/marketplace/components/Checkout.vue'),
            meta: { requiresAuth: true },
            beforeEnter(to, from, next){
              if ( !store.state.auth.isAuthenticated || !store.state.wallet.isConnected ){
                 next({
                   path: '/',
                   replace: true
                 })
              } else {
                 next()
              }
            }
        },
    ]

}
