export default {
    component: () => import(/* webpackChunkName: "homeLayoutMarketplace" */ '@/layouts/HomeLayout'),
    children: [
        {
        path: '',
        name: 'learn',
        component: () => import(/* webpackChunkName: "learn" */ '@/modules/learn/views/LearnView'),
        },
    ]

}