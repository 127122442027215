import {checkUserAndWallet} from "@/services/user/userService";


export const verifyAccountGuard = async (to, from, next) => {
    const check = await checkUserAndWallet();

    if (check) {
        // Redirecciona a la página de inicio de sesión si el usuario no está autenticado
        next({name: 'landing'});
    } else {
        // Permite el acceso a la ruta protegida si el usuario está autenticado
        next();
    }
}
