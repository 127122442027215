import Vue from 'vue'
import VueRouter from 'vue-router'
import authRouter from '../modules/auth/router'
import marketplaceRouter from '../modules/marketplace/router'
import landingRouter from '../modules/landing/router'
import myAccountRouter from '../modules/my-account/router'
import aboutUsRouter from '../modules/about-us/router'
import learnRouter from '../modules/learn/router'
import legalRouter from '../modules/legal/router'
import {isAuthenticatedGuard, userGuard} from "@/router/guards/authGuard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
      ...landingRouter,
  },
  {
    path: '/auth',
    beforeEnter: userGuard,
    ...authRouter,
  },
  {
    path: '/marketplace',
    ...marketplaceRouter,
  },
  {
    path: '/my-account',
    beforeEnter: isAuthenticatedGuard,
    ...myAccountRouter,
  },
  {
    path: '/about-us',
    ...aboutUsRouter
  },
  {
    path: '/learn',
    ...learnRouter
  },
  {
    path: '/legal',
    ...legalRouter
  },
  {
    path:'/*',
    redirect: { name: 'landing' }
    /* name: 'redirect-home',//'marketplace-kastor',
    component: () => import( '@/views/marketplace/Marketplace.vue'), */
  },
]

const router = new VueRouter({
  routes
})

router.afterEach((to) => {
  Vue.$gtag.pageview(to.path)
})

export default router
