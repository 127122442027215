import {verifyAccountGuard} from "@/router/guards/verifyAccountGuard";

export default {
    name: 'my-account',
    component: () => import(/* webpackChunkName: "MyAccountLayout" */ '../layouts/MyAccountLayout'),
    children: [
       {
           path: 'investments',
           name: 'investments',
           component: () => import(/* webpackChunkName: "investments" */ '@/modules/my-account/views/InvestmentsView'),
       },
        {
            path: 'configuration',
            name: 'configuration',
            component: () => import(/* webpackChunkName: "configuration" */ '@/modules/my-account/views/ConfigurationView'),
        },
        {
            beforeEnter: verifyAccountGuard,
            path: 'verify-account',
            name: 'verify-account',
            component: () => import(/* webpackChunkName: "PersonalInfoFormView" */ '@/modules/my-account/views/VerifyAccountView'),
        },
    ]

}
