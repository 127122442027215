
export default {
    component: () => import(/* webpackChunkName: "homeLayoutLanding" */ '@/layouts/HomeLayout'),
    children: [
        {
            path: '',
            name: 'landing',
            component: () => import(/* webpackChunkName: "LandingView" */ '@/modules/landing/views/LandingView'),
        }
    ]

}
